import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  ThemeProvider,
  Tooltip,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserIdentity } from "../../router/PrivateRoute";
import { GlobalAdminMenu } from "./Menus/GlobalAdminMenu";
import { PartnerAdminMenu } from "./Menus/PartnerAdminMenu";
import myTheme from "../../helpers/themeHelper";
import { deepOrange } from "@mui/material/colors";

export const Header = () => {
  const { userIdentity } = useUserIdentity();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const partnerId = userIdentity!.partnerId;

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const navigate = useNavigate();

  const signOut = () => {
    sessionStorage.removeItem("userIdentity");
    navigate("/login");
  };

  const goToSettings = () => {
    
    navigate("/settings");
  };

  return (
    <ThemeProvider theme={myTheme}>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 10,
                display: { xs: "none", md: "flex" },
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Documatik
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {partnerId ? <PartnerAdminMenu /> : <GlobalAdminMenu />}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open account settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ ml: 10 }}>
                  <StyledAvatar ><span>{userIdentity!.username.toUpperCase()[0]}</span></StyledAvatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={goToSettings}>Settings</MenuItem>
                <MenuItem onClick={signOut}>Logout</MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};


const StyledAvatar = styled(Avatar)(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: theme.palette.primary.contrastText,
  [`& span`]: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
}));
