import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { HttpService } from "../../services/HttpService";
import { useNavigate } from "react-router-dom";
import { Alert, LinearProgress } from "@mui/material";
import myTheme from "../../helpers/themeHelper";
import ForgotPassword from "./ForgotPassword";
import { ForgotPasswordDTO } from "./ForgotPassword/ForgotPasswordDTO";
import { toast } from "react-toastify";
import { TryDemoModeDTO } from "./TryDemoMode/TryDemoModeDTO";
import TryDemoMode from "./TryDemoMode";



export default function Login() {
  const navigate = useNavigate();
  const [isBusy, setIsBusy] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>();
  const [isForgotPassModalOpen, setIsForgotPassModalOpen] = React.useState<boolean>(false);
  const [isTryDemoModeOpen, setIsTryDemoModeOpen] = React.useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsBusy(true);
    setError(null);
    const data = new FormData(event.currentTarget);
    const credentials = {
      username: data.get("username"),
      password: data.get("password"),
    };

    HttpService.getInstance(navigate)
      .client.post("/auth/login", credentials)
      .then((response) => {
        sessionStorage.setItem("userIdentity", JSON.stringify(response.data));
        const jsonString = sessionStorage.getItem('userIdentity');

        if (jsonString) {

          const jsonObject = JSON.parse(jsonString);

          if (jsonObject && jsonObject.id) {
            const _userJoinDate = new Date(jsonObject["joinDate"]);
            if (jsonObject.roles[0] == "Demo") {
              const nowDate = new Date();
              _userJoinDate.setDate(_userJoinDate.getDate() + 7);

              if (_userJoinDate < nowDate) {
                navigate("/feedback");
                return;
              }
              else
              {
                navigate("/");
                return;
              }

            }
          }
        }
        navigate("/");
      })
      .catch((error) => {
        setIsBusy(false);
        setError("Something went wrong. Please try again.");
      });
  };

  const handleForgotPassword = () => {
    setIsForgotPassModalOpen(true);
  }

  const handleTryDemoMode = () => setIsTryDemoModeOpen(true);

  const handleForgotPasswordChanged = (data: ForgotPasswordDTO | undefined) => {


    setIsForgotPassModalOpen(false);

    if (data != undefined) {
      HttpService.getInstance(navigate)
        .client.post("/settings/resetPassword", data)
        .then((response) => {
          toast.success(response.data, {
            position: "top-center",
            theme: "colored",
          });
        })
        .catch((error) => {
          toast.error("The data you provided does not match with the accounts in our database.", {
            position: "top-center",
            theme: "colored",
          });
        });
    }


  }

  const handleTryDemoModeChanged = (data: TryDemoModeDTO | undefined) => {


    setIsTryDemoModeOpen(false);

    if (data != undefined) {
      console.log(data);
      HttpService.getInstance(navigate)
        .client.post("/auth/registerDemo", data)
        .then((response) => {
          toast.success("We sent you an email with the credentials for your demo account! Welcome to Documatik!", {
            position: "top-center",
            theme: "colored",
          });
        })
        .catch((error) => {
          toast.error("The data you provided does not match with the accounts in our database.", {
            position: "top-center",
            theme: "colored",
          });
        });
    }


  }

  return (
    <ThemeProvider theme={myTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isBusy}
              sx={{ mt: 3, mb: 1 }}
            >
              Sign In
            </Button>

            {isBusy && <LinearProgress color="secondary" />}
            {error && <Alert severity="error">{error}</Alert>}


          </Box>
          <Grid container sx={{ mt: 3, mb: 1 }}>
            <Grid item xs>
              <Link component="button" variant="body2" onClick={handleForgotPassword}>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component="button" variant="body2" onClick={handleTryDemoMode}>
                Don't have an account? Try demo mode!
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
      {isForgotPassModalOpen && <ForgotPassword onIsForgotPassModalChanged={handleForgotPasswordChanged} />}
      {isTryDemoModeOpen && <TryDemoMode onIsTryDemoModeChanged={handleTryDemoModeChanged} />}
    </ThemeProvider>
  );
}

export { Login };
