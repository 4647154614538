import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import qs from "qs";
import { DocumentDTO } from "../domain/Document/DocumentDTO";
import { HttpService } from "../services/HttpService";
import { DocumentWorkflowStateIds } from "../domain/Document/DocumentWorkflowStatesIds";

const documentCanBeOpened = (document: DocumentDTO) => {
  switch (document.workflowStateId) {
    case DocumentWorkflowStateIds.Uploaded:
    case DocumentWorkflowStateIds.Processing:
    case DocumentWorkflowStateIds.Failed:
      return false;
    default: return true;
  }
};

const downloadFile = (
  id: string,
  fileName: string,
  navigate: NavigateFunction
) => {
  const toastId = toast.loading("Operation is pending.", {
    position: "top-center",
    theme: "colored",
  });

  HttpService.getInstance(navigate)
    .client.get("/documents/downloadFile", {
      params: { id }
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("Download Failed");
      }

      const fileContent = window.atob(res.data);
      const buffer = Uint8Array.from(fileContent, (c) => c.charCodeAt(0));
      const blob = new Blob([buffer]);

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      toast.update(toastId, {
        render: "Download Complete",
        type: "success",
        isLoading: false,
        autoClose: null,
      });
    })
    .catch(() => {
      toast.dismiss(toastId);
    });
};

const exportTo = (
  selectedDocuments: DocumentDTO[],
  exportType: string,
  navigate: NavigateFunction,
  callBack: () => void
) => {
  let path: string = "";
  if (exportType === "excel") {
    path = "/documents/exportExcel";
  } else if (exportType === "xml") {
    path = "/documents/exportXML";
  }else if(exportType === "excel NextUP")
    {
      path = "/documents/exportExcelNextUP";
    } 
  else {
    throw new Error("Only Excel and XML types are supported");
  }

  const toastId = toast.loading("Operation is pending.", {
    position: "top-center",
    theme: "colored",
  });

  HttpService.getInstance(navigate)
    .client.get(path, {
      params: {
        ids: selectedDocuments.map((document) => document.id),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("Request Failed");
      }

      const fileContent = window.atob(res.data);
      const buffer = Uint8Array.from(fileContent, (c) => c.charCodeAt(0));
      const blob = new Blob([buffer]);

      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      if (exportType === "excel") {
        link.download = `${new Date().toLocaleString()}.xlsx`;
        link.click();
        toast.update(toastId, {
          render: "The accounting journals were successfully exported.",
          type: "success",
          isLoading: false,
          autoClose: null,
        });
      }else if (exportType === "excel NextUP") {
        link.download = `${new Date().toLocaleString()}.xlsx`;
        link.click();
        toast.update(toastId, {
          render: "The accounting journals were successfully exported.",
          type: "success",
          isLoading: false,
          autoClose: null,
        });
      } 
      else if (exportType === "xml") {
        const parser = new DOMParser();
        // eslint-disable-next-line no-control-regex
        const cleanContent = fileContent.replace(/[^\x00-\x7F]+/g, "");
        const xml = parser.parseFromString(cleanContent, "text/xml");
        var invoiceNr;
        var invoiceDate;
        var vendorTaxId;
        try {
          var vendorTaxIdCollection = xml.getElementsByTagName("FurnizorCIF");
          for (let i = 0; i < vendorTaxIdCollection.length; i++) {
            if (vendorTaxIdCollection[i].childNodes[0] !== undefined) {
              vendorTaxId = vendorTaxIdCollection[i].childNodes[0].nodeValue;
              break;
            } else vendorTaxId = "undefined_CIF";
          }

          var invoiceNrCollection = xml.getElementsByTagName("FacturaNumar");
          for (let i = 0; i < invoiceNrCollection.length; i++) {
            if (invoiceNrCollection[i].childNodes[0] !== undefined) {
              invoiceNr = invoiceNrCollection[i].childNodes[0].nodeValue;
              break;
            } else invoiceNr = "undefined_number";
          }

          var invoiceDateCollection = xml.getElementsByTagName("FacturaData");
          for (let i = 0; i < invoiceDateCollection.length; i++) {
            if (invoiceDateCollection[i].childNodes[0] !== undefined) {
              invoiceDate = invoiceDateCollection[i].childNodes[0].nodeValue;
              break;
            } else invoiceDate = "undefined_date";
          }
          try {
            let dateArray = invoiceDate?.split("/");
            if (dateArray?.length === 1)
              dateArray = dateArray = invoiceDate?.split(".");
            if (dateArray?.length === 1)
              dateArray = dateArray = invoiceDate?.split("-");
            if (dateArray) {
              link.download = `F_${vendorTaxId}_${invoiceNr}_${dateArray[0]}_${dateArray[1]}_${dateArray[2]}.xml`;
            } else {
              link.download = `F_${vendorTaxId}_${invoiceNr}_undefined_date.xml`;
            }
          } catch (e) {
            link.download = `F_${vendorTaxId}_${invoiceNr}_undefined_date.xml`;
          }

          link.click();
          toast.update(toastId, {
            render: "The accounting journals were successfully exported.",
            type: "success",
            isLoading: false,
            autoClose: null,
          });
          callBack();
        } catch (e) {
          toast.update(toastId, {
            render:
              "The exported note must have correct values for vendor CIF, note number and date",
            type: "error",
            isLoading: false,
            autoClose: null,
          });
        }
      }
    })
    .catch((error) => {
      toast.dismiss(toastId);
    });
};

export { documentCanBeOpened, downloadFile, exportTo };
