import { createTheme } from "@mui/material";

const myTheme = createTheme({
    palette: {
      primary: {
        main: "#20BAD1",
        contrastText: "#fff"
      },
      
    },
    typography:{
        
    }
});

export default myTheme;