import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import ValidationSchema from '../../Settings/ChangePassword/index.validationSchema';
import { ChangePasswordDTO } from './ChangePasswordDTO';

const ChangePasswordPopup: React.FC<{ onIsModalOpenChanged: (newPassword: ChangePasswordDTO | undefined, requestedPassword: boolean) => void }> = (props) => {

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<ChangePasswordDTO>({
        resolver: yupResolver(ValidationSchema),
    });

    const handleChangePassword = () => {

        props.onIsModalOpenChanged(undefined, true);
    }
    const handleDismissPopup = () => {

        props.onIsModalOpenChanged(undefined, false);
    }
    const handleOnSubmit = (data: ChangePasswordDTO) => {

        props.onIsModalOpenChanged(data, false);
    }
    return (
        <React.Fragment>
            <Dialog open={true} >
                <DialogTitle textAlign="center">Change Password</DialogTitle>

                <DialogContent dividers>
                    <DialogContentText>
                        <Typography sx={{ ml: 3 }}>
                            Click "Receive new password" if you want to receive a new secure
                            password on the email associated with this account.
                        </Typography>
                    </DialogContentText>
                    <Box justifyContent="center" sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="button"
                            type="button"
                            onClick={handleChangePassword}
                            sx={{ mt: 3, mb: 1, mr: 1 }}
                        >
                            Receive new password
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            component="button"
                            type="button"
                            onClick={handleDismissPopup}
                            sx={{ mt: 3, mb: 1, ml: 1 }}
                        >
                            Cancel
                        </Button>
                    </Box>
                </DialogContent>
                <DialogContent dividers>
                    <DialogContentText>
                        <Typography sx={{ ml: 3 }}>
                            Complete the following form and click on the "Submit" button in order to
                            change your current password to one of your own liking.
                        </Typography>
                    </DialogContentText>
                    <Box component="form" justifyContent="center" onSubmit={handleSubmit(handleOnSubmit)}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Current Password"
                            type="password"
                            autoFocus
                            {...register("password")}
                            error={!!errors.password}
                            helperText={errors?.password?.message}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="New Password"
                            type="password"
                            autoFocus
                            {...register("newPassword")}
                            error={!!errors.newPassword}
                            helperText={errors?.newPassword?.message}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Confirm New Password"
                            type="password"
                            autoFocus
                            {...register("newPasswordConfirmation")}
                            error={!!errors.newPasswordConfirmation}
                            helperText={errors?.newPasswordConfirmation?.message}
                        />
                        <Box justifyContent="center" sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                component="button"
                                sx={{ mt: 3, mb: 1, mr: 1 }}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                component="button"
                                onClick={handleDismissPopup}
                                sx={{ mt: 3, mb: 1, ml: 1 }}
                            >
                                Cancel
                            </Button>
                        </Box>

                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default ChangePasswordPopup