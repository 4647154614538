import {
  styled,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CssBaseline,
  Container,
  Typography,
  TablePagination,
  ThemeProvider,
  makeStyles,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PartnerDTO } from "../../domain/Partner/PartnerDTO";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { HttpService } from "../../services/HttpService";
import { Box, GlobalStyles } from "@mui/system";
import { Partner } from "./Partner";
import RefreshIcon from "@mui/icons-material/Refresh";
import HandshakeIcon from "@mui/icons-material/Handshake";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Users } from "../../components/Users";
import _ from "lodash";
import myTheme from "../../helpers/themeHelper";
import {
  BorderAll,
  BorderBottom,
  BorderColor,
  BorderTop,
} from "@mui/icons-material";

enum ModalType {
  Partner,
  Users,
}

const Partners: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState<Date>();
  const [partners, setPartners] = useState<PartnerDTO[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<{
    modalType: ModalType;
    partnerId?: string;
  }>();
  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>({ page: 0, rowsPerPage: 10 });

  useEffect(() => {
    HttpService.getInstance(navigate)
      .client.get<PartnerDTO[]>("/Partners")
      .then((response) => {
        setPartners(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refresh, navigate]);

  const getPartners = () => {
    if (!partners) return null;
    return _.orderBy(partners, (partner) => partner.name, "asc")
      .slice(
        pagination.page * pagination.rowsPerPage,
        (pagination.page + 1) * pagination.rowsPerPage
      )
      ?.map((partner) => (
        <StyledTableRow key={partner.id}>
          <StyledTableCell
            component="th"
            scope="row"
            onClick={() => {
              navigate(`/${partner.id}/Companies`);
            }}
          >
            {partner.name}
          </StyledTableCell>
          <StyledTableCell align="right">{partner.cui}</StyledTableCell>
          <StyledTableCell align="right">{partner.email}</StyledTableCell>
          <StyledTableCell align="right">{partner.phone}</StyledTableCell>
          <StyledTableCell align="right">
            <span
              style={{ margin: "0 0 0 10px" }}
              onClick={() => {
                setIsModalOpen({
                  modalType: ModalType.Partner,
                  partnerId: partner.id,
                });
              }}
            >
              <EditIcon />
            </span>
            <span
              style={{ margin: "0 0 0 10px" }}
              onClick={() => {
                setIsModalOpen({
                  modalType: ModalType.Users,
                  partnerId: partner.id,
                });
              }}
            >
              <PersonAddIcon />
            </span>
          </StyledTableCell>
        </StyledTableRow>
      ));
  };

  const getPartner = (partnerId?: string): PartnerDTO => {
    if (!partnerId) return new PartnerDTO();
    const partner = partners.find((item) => item.id === partnerId);

    if (!partner) return new PartnerDTO();
    return partner;
  };

  return (
    <>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Partners
        </Typography>
        <ThemeProvider theme={myTheme}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              startIcon={<HandshakeIcon />}
              variant="contained"
              onClick={() => {
                setIsModalOpen({
                  modalType: ModalType.Partner,
                  partnerId: undefined,
                });
              }}
            >
              Add Partner
            </Button>

            <Button onClick={() => setRefresh(new Date())}>
              <RefreshIcon /> Refresh
            </Button>
          </Box>
          <TableContainer component={Paper} sx={{ mt: 2, mr: 20 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Partner Name</StyledTableCell>
                  <StyledTableCell align="right">Partner CUI</StyledTableCell>
                  <StyledTableCell align="right">Company Email</StyledTableCell>
                  <StyledTableCell align="right">Company Phone</StyledTableCell>
                  <StyledTableCell align="right">Edit</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>{getPartners()}</TableBody>
            </Table>
          </TableContainer>
          <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={partners ? partners.length : 0}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={(event, index) => {
            setPagination({ ...pagination, page: index });
          }}
          onRowsPerPageChange={(event) => {
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value),
            });
          }}
        />
        {isModalOpen && isModalOpen.modalType === ModalType.Partner && (
          <Partner
            partner={getPartner(isModalOpen.partnerId)}
            onClose={() => {
              setIsModalOpen(undefined);
              setRefresh(new Date());
            }}
          />
        )}
        {isModalOpen &&
          isModalOpen.modalType === ModalType.Users &&
          isModalOpen.partnerId && (
            <Users
              partnerId={isModalOpen.partnerId}
              onClose={() => setIsModalOpen(undefined)}
            />
          )}
        </ThemeProvider>
      </Container>
    </>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export { Partners };
