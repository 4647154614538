import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
const config: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URI,
  headers: {
    "Content-type": "application/json",
  },
};
 
class HttpService {
  private static instance: HttpService;
  public client: AxiosInstance;

  private constructor(navigate: NavigateFunction) {
    this.client = axios.create(config);
    this.client.interceptors.response.use((response) => { return response; }, (error) => {
      if (401 === error.response.status) {
        navigate('/login');
      }
      else{
        toast(error.response.data,{position: "top-center",
        type: "error", theme: "colored",});
      }
    });

    this.client.interceptors.request.use((request) => {
      const userIdentity = sessionStorage.getItem("userIdentity");
      const identity = userIdentity && JSON.parse(userIdentity);
      if (identity)
        request!.headers!.Authorization = `Bearer ${identity?.token}`;
      return request;
    });
  }

  public static getInstance(navigate: NavigateFunction): HttpService {
    if (!HttpService.instance) {
      HttpService.instance = new HttpService(navigate);
    }
    return HttpService.instance;
  }
}

export { HttpService };
