import * as yup from "yup";

export default yup.object().shape({
  username: yup.string().required("This field is required."),
  emailAddress: yup.string().required("This field is required.").email(),
  roleName: yup.string().required("This field is required."),
  firstName: yup.string().required("This field is required."),
  lastName: yup.string().required("This field is required."),
  phone: yup.string().required("This field is required.")
});
