import { CssBaseline } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AppRoles } from "../domain/Identity/AppRoles";
import { Companies } from "../screens/Companies/index";
import { Documents } from "../screens/Documents";
import { Login } from "../screens/Login";
import { Partners } from "../screens/Partners";
import { Reporting } from "../screens/Partners/Reporting";
import { Unauthorized } from "../screens/Unauthorized/Unauthorized";
import { HomeRoute } from "./HomeRoute";
import { PrivateRoute } from "./PrivateRoute";
import Settings from "../screens/Settings";
import FeedbackForm from "../screens/Feedback";

export const Router = () => {
  return (
    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route path="login" element={<Login />} />
        <Route
          element={
            <PrivateRoute
              roles={[
                AppRoles.GlobalAdmin,
                AppRoles.PartnerAdmin,
                AppRoles.Accountant,
                AppRoles.Demo,
              ]}
            />
          }
        >
          <Route path="/" element={<HomeRoute />} />
        </Route>

        
        <Route path="/feedback" element={<FeedbackForm />} />
        

        <Route element={<PrivateRoute roles={[AppRoles.PartnerAdmin]} />}>
          <Route path="/" element={<Navigate to="/companies" replace />} />
        </Route>

        <Route element={<PrivateRoute roles={[AppRoles.GlobalAdmin]} />}>
          <Route path="/reporting" element={<Reporting />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              roles={[
                AppRoles.GlobalAdmin,
                AppRoles.PartnerAdmin,
                AppRoles.Accountant,
                AppRoles.Demo,
              ]}
            />
          }
        >
          <Route path="/documents/:companyCui" element={<Documents />} />
        </Route>
        <Route
          element={
            <PrivateRoute
              roles={[
                AppRoles.GlobalAdmin,
                AppRoles.PartnerAdmin,
                AppRoles.Accountant,
                AppRoles.Demo,
              ]}
            />
          }
        >
          <Route path="/:partnerId/Companies" element={<Companies />} />
        </Route>
        <Route element={<PrivateRoute roles={[AppRoles.GlobalAdmin]} />}>
          <Route path="/partners" element={<Partners />} />
        </Route>
        <Route path="*" element={<Unauthorized />} />

        <Route
          element={
            <PrivateRoute
              roles={[
                AppRoles.GlobalAdmin,
                AppRoles.PartnerAdmin,
                AppRoles.Accountant,
                AppRoles.Demo,
              ]}
            />
          }
        >
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Routes>

      

    </BrowserRouter>
  );
};
