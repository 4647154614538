import { DocumentDTO } from "../../domain/Document/DocumentDTO";

enum SortDirection {
  Ascending = "asc",
  Descending = "desc",
}
interface ISorting<T> {
  sortDirection: SortDirection;
  sortBy: keyof T;
}

type SelectableDocument = {
  document: DocumentDTO, 
  isSelected: boolean
}

type DocumentsListState = {
  documents: SelectableDocument[],
  openedDocument?: SelectableDocument,
  shouldRefreshDocuments: boolean
}

export { SortDirection };
export type { ISorting, DocumentsListState, SelectableDocument };
