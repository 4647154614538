import React, { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import { Copyright } from "../Copyright";
import { Header } from "./Header";

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <main>
        <Header />
        <Outlet />
        {children}
        <Copyright sx={{ mb: 5, mt: 20 }} />
      </main>
    </>
  );
};
