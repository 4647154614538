import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material'
import React from 'react'
import { ForgotPasswordDTO } from './ForgotPasswordDTO';
import { useForm } from 'react-hook-form';
import ValidationSchema from '../ForgotPassword/index.validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';

const ForgotPassword: React.FC<{ onIsForgotPassModalChanged: (data: ForgotPasswordDTO | undefined) => void}> = (props) => {

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<ForgotPasswordDTO>({
        resolver: yupResolver(ValidationSchema),
    });


    const handleCancel = () => {
        props.onIsForgotPassModalChanged(undefined);
    }

    const handleOnSubmit = (data : ForgotPasswordDTO) => {
        props.onIsForgotPassModalChanged(data);
    }


  return (
    <React.Fragment>
            <Dialog open={true} >
                <DialogTitle textAlign="center">Change Password</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        <Typography sx={{ ml: 3 }}>
                            Complete the following form and click on the "Submit" button in order to
                            receive an email with a new password.
                        </Typography>
                    </DialogContentText>
                    <Box component="form" justifyContent="center" onSubmit={handleSubmit(handleOnSubmit)}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Username"
                            autoFocus
                            {...register("username")}
                            error={!!errors.username}
                            helperText={errors?.username?.message}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Email"
                            type="email"
                            autoFocus
                            {...register("emailAddress")}
                            error={!!errors.emailAddress}
                            helperText={errors?.emailAddress?.message}
                        />
                        <Box justifyContent="center" sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                component="button"
                                sx={{ mt: 3, mb: 1, mr: 1 }}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                component="button"
                                onClick={handleCancel}
                                sx={{ mt: 3, mb: 1, ml: 1 }}
                            >
                                Cancel
                            </Button>
                        </Box>

                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
  )
}

export default ForgotPassword