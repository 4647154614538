import { useEffect, useState } from "react";
import { HttpService } from "../../../services/HttpService";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import ValidationSchema from "../Company/index.validationschema";
import { CompanyDTO } from "../../../domain/Company/CompanyDTO";
import { toast } from "react-toastify";

type Props = {
  company: CompanyDTO;
  onClose: () => void;
  onSubmit: () => void;
};
const Company: React.FC<Props> = (props) => {
  const { company, onClose } = props;
  const navigate = useNavigate();
  const { partnerId } = useParams();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CompanyDTO>({
    resolver: yupResolver(ValidationSchema),
    defaultValues: company,
  });

  const handleOnSubmit = (data: CompanyDTO) => {
    const payload = {
      id: data.id,
      name: data.name,
      cui: data.cui,
      email: data.email,
      phone: data.phone,
    };
    if (company.id) {
      HttpService.getInstance(navigate)
        .client.put(`/Companies`, payload)
        .then((response) => {
          toast.success("Company edited successfully!", {
            position: "top-center",
            theme: "colored",
          });
          props.onSubmit();
          onClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      HttpService.getInstance(navigate)
        .client.post(`/Companies/${partnerId}`, payload)
        .then((response) => {
          toast.success("Company created successfully!", {
            position: "top-center",
            theme: "colored",
          });
          onClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Dialog open={true} onClose={() => onClose()}>
        <DialogTitle>Complete Data</DialogTitle>
        <DialogContentText>
          <Typography sx={{ ml: 3 }}>
            To add your company, please provide the following data
          </Typography>
        </DialogContentText>
        <DialogContent dividers>
          <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
            <TextField
              margin="normal"
              fullWidth
              label="Name"
              autoFocus
              {...register("name")}
              error={!!errors.name}
              helperText={errors?.name?.message}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Cui"
              autoFocus
              {...register("cui")}
              error={!!errors.cui}
              helperText={errors?.cui?.message}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Email"
              autoFocus
              {...register("email")}
              error={!!errors.email}
              helperText={errors?.email?.message}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Phone"
              autoFocus
              {...register("phone")}
              error={!!errors.phone}
              helperText={errors?.phone?.message}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              component="button"
              sx={{ mt: 2 }}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export { Company };
