import _ from "lodash";
import { Navigate } from "react-router-dom";
import { AppRoles } from "../domain/Identity/AppRoles";
import { useUserIdentity } from "./PrivateRoute";

export const HomeRoute = () => {
    const { userIdentity } = useUserIdentity();

    if (userIdentity?.roles.includes(AppRoles.GlobalAdmin))
        return <Navigate to="/partners" replace />;

    if (userIdentity?.roles.includes(AppRoles.PartnerAdmin))
        return <Navigate to={`/${userIdentity.partnerId}/Companies`} replace />;

    return <Navigate to={`/${userIdentity?.partnerId}/Companies`} replace />;
};
