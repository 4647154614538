import { List, ListItem } from "@mui/material";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

export const GlobalAdminMenu = () => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <List sx={{ display: "flex" }}>
      <ListItem
        component={Link}
        to="/partners"
        button
        key={"1"}
        selected={"/partners" === path}
      >
        <span>Partners</span>
      </ListItem>
      <ListItem
        component={Link}
        to="/reporting"
        button
        key={"2"}
        selected={"/reporting" === path}
      >
        <span>Reporting</span>
      </ListItem>
    </List>
  );
};
