import { Box, Button, Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, TextField, Typography } from '@mui/material'
import React from 'react'
import { TryDemoModeDTO } from './TryDemoModeDTO';
import { useForm } from 'react-hook-form';
import ValidationSchema from '../TryDemoMode/index.validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';

const TryDemoMode: React.FC<{ onIsTryDemoModeChanged: (data: TryDemoModeDTO | undefined) => void }> = (props) => {

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<TryDemoModeDTO>({
        resolver: yupResolver(ValidationSchema),
    });


    const handleCancel = () => {
        props.onIsTryDemoModeChanged(undefined);
    }

    const handleOnSubmit = (data: TryDemoModeDTO) => {
        props.onIsTryDemoModeChanged(data);
    }


    return (
        <React.Fragment>
            <Dialog open={true} >
                <DialogTitle textAlign="center">Try our demo mode!</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        <Typography sx={{ ml: 3 }}>
                            Complete the following form and click on the "Submit" button in order to
                            create your free demo account for Documatik!
                        </Typography>
                    </DialogContentText>
                    <Box component="form" justifyContent="center" onSubmit={handleSubmit(handleOnSubmit)}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Username"
                            autoFocus
                            {...register("username")}
                            error={!!errors.username}
                            helperText={errors?.username?.message}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Email"
                            type="email"
                            autoFocus
                            {...register("emailAddress")}
                            error={!!errors.emailAddress}
                            helperText={errors?.emailAddress?.message}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Password"
                            type="password"
                            autoFocus
                            {...register("password")}
                            error={!!errors.password}
                            helperText={errors?.password?.message}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Confirm Password"
                            type="password"
                            autoFocus
                            {...register("passwordConfirmation")}
                            error={!!errors.passwordConfirmation}
                            helperText={errors?.passwordConfirmation?.message}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="First name"
                            autoFocus
                            {...register("firstName")}
                            error={!!errors.firstName}
                            helperText={errors?.firstName?.message}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Last name"
                            autoFocus
                            {...register("lastName")}
                            error={!!errors.lastName}
                            helperText={errors?.lastName?.message}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Phone Number"
                            autoFocus
                            {...register("phone")}
                            error={!!errors.phone}
                            helperText={errors?.phone?.message}
                        />

                        <FormControl error={!!errors.agreement}>
                            <FormControlLabel
                                control={<Checkbox {...register("agreement")} color="primary" />}
                                label={
                                    <span>
                                        I agree with <a target="_blank" href='https://www.documatik.com/termeni-si-conditii/'>T&C</a> and <a target="_blank" href='https://www.documatik.com/politica-de-confidentialitate/'>GDPR</a>.
                                    </span>
                                }
                            />
                            <FormHelperText>
                                {errors?.agreement?.message}
                            </FormHelperText>
                        </FormControl>

                        <Box justifyContent="center" sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                component="button"
                                sx={{ mt: 3, mb: 1, mr: 1 }}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                component="button"
                                onClick={handleCancel}
                                sx={{ mt: 3, mb: 1, ml: 1 }}
                            >
                                Cancel
                            </Button>
                        </Box>

                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default TryDemoMode;