import * as yup from "yup"

export default yup.object().shape({
    question1: yup.string().required("This field is required.").nullable(),
    question2: yup.string().required("This field is required.").nullable(),
    question3: yup.string().required("This field is required.").nullable(),
    question4: yup.boolean().required("This field is required.").nullable(),
    question5: yup.string().when('question4',{
        is: true,
        then: yup.string().required("This field is required when question 4 answear is Yes."),
        otherwise: yup.string(),
    }),
    question6: yup.string().required("This field is required.").nullable(),
    question7: yup.string().required("This field is required.").nullable(),
    question8: yup.string().required("This field is required."),
    question9: yup.boolean().required("This field is required.").nullable(),
    question10: yup.string().required("This field is required."),
})