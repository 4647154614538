import * as yup from "yup";

export default yup.object().shape({
    username: yup.string().required("This field is required."),
    emailAddress: yup.string().required("This field is required.").email(),
    password: yup.string().required("New Password is required!").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    firstName: yup.string().required("This field is required."),
    lastName: yup.string().required("This field is required."),
    phone: yup.string().required("This field is required."),
    agreement: yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted.")
});
