import React from "react";
import { Box, Checkbox, IconButton, TableBody, TableCell, Theme } from "@mui/material";
import { StyledTableRow, StyledTableCell } from "./index.styled";
import DocumentWorkflowStateDTO from "../../../domain/Document/DocumentWorkflowStateDTO";
import { SelectableDocument } from "./reducer";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { documentCanBeOpened, downloadFile } from "../../../helpers/DocumentHelpers";
import { NavigateFunction } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import myTheme from "../../../helpers/themeHelper";

const DocumentCheckboxCellElement: React.FC<{
  document: SelectableDocument;
  onDocumentCheckChanged: (document: SelectableDocument) => void;
}> = (props) => (
  <TableCell padding="checkbox" sx={{ pl: "15px" }}>
    <Checkbox
      color="primary"
      checked={props.document.isSelected}
      onChange={(event, checked) =>
        props.onDocumentCheckChanged({
          document: props.document.document,
          isSelected: checked,
        })
      }
    />
  </TableCell>
);

const DocumentNameCellElement: React.FC<{
  document: SelectableDocument;
  onDocumentNameClick: (document: SelectableDocument) => void;
}> = (props) => (
  <StyledTableCell
    align="left"
    component="th"
    scope="row"
    onClick={() => {
      if (documentCanBeOpened(props.document.document)){
        props.onDocumentNameClick(props.document)
      }
    }}
  >
    {props.document.document.fileName}
  </StyledTableCell>
);

const DocumentCreationDateCellElement: React.FC<{ creationDate: Date }> = (props) => {
  const newDate = new Date(props.creationDate.toString());
  return (
    <StyledTableCell align="left">
      {newDate.toLocaleDateString("ro", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })}
    </StyledTableCell>
  );
};

const DocumentTableRowElement: React.FC<{
  document: SelectableDocument;
  documentState: DocumentWorkflowStateDTO | undefined;
  onDocumentCheckChanged: (document: SelectableDocument) => void;
  onDocumentNameClick: (document: SelectableDocument) => void;
  myTheme: Theme,
  navigate: NavigateFunction;
}> = (props) => (
  <ThemeProvider theme={props.myTheme}>
    <StyledTableRow>
    <DocumentCheckboxCellElement
      onDocumentCheckChanged={props.onDocumentCheckChanged}
      document={props.document}
    />
    <DocumentNameCellElement
      document={props.document}
      onDocumentNameClick={props.onDocumentNameClick}
    />
    <DocumentCreationDateCellElement
      creationDate={props.document.document.creationDate}
    />
    <StyledTableCell align="center">
      <IconButton
        onClick={() =>
          downloadFile (
            props.document.document.id,
            props.document.document.fileName,
            props.navigate
          )
        }
      >
        <FileDownloadIcon color="primary"/>
      </IconButton >
    </StyledTableCell>
  </StyledTableRow>
  </ThemeProvider>
  
);

const DocumentTableBodyElement: React.FC<{
  documents: SelectableDocument[];
  documentStates: DocumentWorkflowStateDTO[];
  onDocumentCheckChanged: (document: SelectableDocument) => void;
  onDocumentNameClick: (document: SelectableDocument) => void;
  navigate: NavigateFunction
}> = (props) => (
  <ThemeProvider theme={myTheme}>
    <TableBody>
    {props.documents?.map((document, index) => (
      <DocumentTableRowElement
        key={index}
        document={document}
        documentState={props.documentStates.find(
          (state) => state.id === document.document.workflowStateId
        )}
        onDocumentCheckChanged={props.onDocumentCheckChanged}
        onDocumentNameClick={props.onDocumentNameClick}
        myTheme={myTheme}
        navigate={props.navigate}
      />
    ))}
  </TableBody>
  </ThemeProvider>
  
);

export { DocumentTableBodyElement };
