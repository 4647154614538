import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  ThemeProvider,
  Typography,
  styled,
} from "@mui/material";
import { FilePond } from "react-filepond";
import { NavigateFunction } from "react-router-dom";
import { CompanyDTO } from "../../../domain/Company/CompanyDTO";
import { DocumentTypeDTO } from "../../../domain/Document/DocumentType";
import { Delete } from "@mui/icons-material";
import { FilePondFile, FileStatus } from "filepond";
import myTheme from "../../../helpers/themeHelper";
import "./index.css";

const DocumentsUploader: React.FC<{
  company: CompanyDTO;
  documentType: DocumentTypeDTO;
  navigate: NavigateFunction;
}> = (props) => {
  const { company, documentType: value, navigate } = props;
  const [files, setFiles] = useState<{ file: File; pondFile: FilePondFile }[]>(
    []
  );

  const userIdentity = sessionStorage.getItem("userIdentity");
  const identity = userIdentity && JSON.parse(userIdentity);
  const onClearList = () => {
    const isFileProcessed = (pondFile: FilePondFile) => {
      switch (pondFile.status) {
        case FileStatus.PROCESSING_COMPLETE:
        case FileStatus.PROCESSING_ERROR:
          return true;
        default:
          return false;
      }
    };

    const processedDocuments = files.filter(
      ({ pondFile }) => !isFileProcessed(pondFile)
    );
    setFiles(processedDocuments);
  };

  const label_temp = () => {
    return (
      <label>
        <input value="foo.jpg" data-type="local" checked type="checkbox" />
        foo.jpeg
      </label>
    );
  };

  

  return (
    <ThemeProvider theme={myTheme}>
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Upload document
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="p"
        >
          {company.name}
        </Typography>
        <Box>
          <FilePond
          credits = {undefined}
            files={files.map((file) => file.file)}
            onupdatefiles={(data) => {
              setFiles(
                data.map((item) => ({
                  file: item.file as File,
                  pondFile: item,
                }))
              );
            }}
            onerror={(error) => {
              if (error.code === 401) navigate("/login");
            }}
            allowMultiple={true}
            
            allowFileTypeValidation={true}
            labelFileTypeNotAllowed={"File of invalid type"}
            labelFileProcessingError={(error) => {
              if (error.code === 409) return "File has already been uploaded!";
              return "Error during upload";
            }}
            acceptedFileTypes={[
              "application/pdf",
              "image/png",
              "image/jpeg",
              "image/bmp",
              "image/tiff",
            ]}
            maxFiles={10}
            server={{
              url: `${process.env.REACT_APP_API_URI}/documents/${company.cui}/${value}`,
              timeout: 10000,
              headers: {
                Authorization: `Bearer ${identity?.token}`,
              },
            }}
            name="files"
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          />
          {files.length > 0 && (
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                size="large"
                onClick={onClearList}
                sx={{
                  bottom: "10px",
                  color: "gray",
                }}
              >
                <Delete color="primary"/>
                <span>Clear</span>
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default DocumentsUploader;
