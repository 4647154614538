import React, { useState } from "react";
import { NavigateFunction } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import _ from "lodash";
import { DocumentDTO } from "../../../domain/Document/DocumentDTO";
import DocumentWorkflowStateDTO from "../../../domain/Document/DocumentWorkflowStateDTO";
import { Invoice } from "./Invoice";

const Index: React.FC<{ 
  document: DocumentDTO,
  documentStates: DocumentWorkflowStateDTO[],
  revalidateDocument: () => void,
  navigate: NavigateFunction,
  onClose: () => void
}> = (props) => (
  <Dialog
    fullWidth={true}
    maxWidth="xl"
    open={true}
    onClose={props.onClose}
  >
    <DialogTitle>{props.document.fileName}</DialogTitle>
      <DialogContent>
          <Invoice 
            document={props.document}
            navigate={props.navigate}  
            onClose={props.onClose}
            revalidateDocument={props.revalidateDocument}
          />
      </DialogContent>
  </Dialog>
);

export default Index;
