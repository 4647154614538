import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  CssBaseline,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { CompanyDTO } from "../../domain/Company/CompanyDTO";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router-dom";
import { HttpService } from "../../services/HttpService";
import { Box, GlobalStyles } from "@mui/system";
import { Company } from "./Company";
import { useUserIdentity } from "../../router/PrivateRoute";
import { AppRoles } from "../../domain/Identity/AppRoles";
import { toast } from "react-toastify";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Users } from "../../components/Users";
import _ from "lodash";
import { PartnerDTO } from "../../domain/Partner/PartnerDTO";
import myTheme from "../../helpers/themeHelper";

enum ModalType {
  Company,
  Users,
}
export const Companies: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const { userIdentity } = useUserIdentity();
  const [refresh, setRefresh] = useState<Date>();
  const [companies, setCompanies] = useState<CompanyDTO[]>();
  const [partner, setPartner] = useState<PartnerDTO>();
  const { partnerId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState<{
    modalType: ModalType;
    partnerId?: string;
    companyId?: string;
  }>();
  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>({ page: 0, rowsPerPage: 10 });

  useEffect(() => {
    if (partnerId) {
      HttpService.getInstance(navigate)
        .client.get<CompanyDTO[]>(`/Companies/${partnerId}`)
        .then((response) => {
          setCompanies(response.data);
        });

      HttpService.getInstance(navigate)
        .client.get<PartnerDTO>(`/Partners/${partnerId}`)
        .then((response) => {
          setPartner(response.data);
        });
    }
  }, [partnerId, refresh, navigate]);

  const getCompanies = () => {
    if (!companies) return null;
    return _.orderBy(companies, (company) => company.name, "asc")
      .slice(
        pagination.page * pagination.rowsPerPage,
        (pagination.page + 1) * pagination.rowsPerPage
      )
      ?.map((company) => (
        <StyledTableRow key={company.id}>
          <StyledTableCell
            onClick={() => {
              const jsonString = sessionStorage.getItem('userIdentity');

              if (jsonString) {

                const jsonObject = JSON.parse(jsonString);

                if (jsonObject && jsonObject.id) {

                  if (jsonObject.roles[0] == "Demo") {
                    if (company.id == jsonObject.companyId) {
                      navigate(`/documents/${company.cui}`);
                    }
                    else {
                      toast.error("Can't open companies that you do not belong to!", {
                        position: "top-center",
                        theme: "colored",
                      });
                    }
                  }
                  else {
                    navigate(`/documents/${company.cui}`);
                  }


                }
              }
            }}
            component="th"
            scope="row"
          >
            {company.name}
          </StyledTableCell>
          <StyledTableCell align="right">{company.cui}</StyledTableCell>
          <StyledTableCell align="right">{company.email}</StyledTableCell>
          <StyledTableCell align="right">{company.phone}</StyledTableCell>
          <StyledTableCell align="right">
            <span
              style={{ margin: "0 0 0 10px" }}
              onClick={() => {
                if (userIdentity?.roles[0] !== AppRoles.Accountant
                  && userIdentity?.roles[0] !== AppRoles.Demo
                )
                  setIsModalOpen({
                    modalType: ModalType.Company,
                    companyId: company.id,
                  });
                else {
                  toast.error("Can't edit with an accountant/demo account", {
                    position: "top-center",
                    theme: "colored",
                  });
                }
              }}
            >
              <EditIcon />
            </span>
            <span
              style={{ margin: "0 0 0 10px" }}
              onClick={() => {
                if (userIdentity?.roles[0] !== AppRoles.Accountant
                  && userIdentity?.roles[0] !== AppRoles.Demo
                )
                  setIsModalOpen({
                    modalType: ModalType.Users,
                    partnerId: company.partnerId,
                  });
                else {
                  toast.error("Can't edit with an accountant/demo account", {
                    position: "top-center",
                    theme: "colored",
                  });
                }
              }}
            >
              <PersonAddIcon />
            </span>
          </StyledTableCell>
        </StyledTableRow>
      ));
  };

  const getCompany = (companyId?: string): CompanyDTO => {
    if (!partnerId) return new CompanyDTO();
    const company = companies?.find((item) => item.id === companyId);

    if (!company) return new CompanyDTO();
    return company;
  };
  return (
    <>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <ThemeProvider theme={myTheme}>
        <Container
          disableGutters
          maxWidth="md"
          component="main"
          sx={{ pt: 8, pb: 6 }}
        >
          <Typography
            component="h1"
            variant="h2"
            color="text.primary"
            align="center"
            gutterBottom
          >
            {partner?.name}
          </Typography>
          <Typography color="gray" align="center" gutterBottom>
            CUI: {partner?.cui}
          </Typography>
          <Typography color="gray" align="center" gutterBottom>
            {partner?.email} - {partner?.phone}
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            {(userIdentity?.roles[0] !== AppRoles.Accountant
              && userIdentity?.roles[0] !== AppRoles.Demo
            ) && (
                <Button
                  startIcon={<AddBusinessIcon />}
                  variant="contained"
                  onClick={() => {
                    setIsModalOpen({
                      modalType: ModalType.Company,
                      partnerId: undefined,
                    });
                  }}
                >
                  Add Company
                </Button>
              )}
          </Box>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Company Name</StyledTableCell>
                  <StyledTableCell align="right">Company CUI</StyledTableCell>
                  <StyledTableCell align="right">Company Email</StyledTableCell>
                  <StyledTableCell align="right">Company Phone</StyledTableCell>
                  <StyledTableCell align="right"> Edit </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>{getCompanies()}</TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={companies ? companies.length : 0}
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            onPageChange={(event, index) => {
              setPagination({ ...pagination, page: index });
            }}
            onRowsPerPageChange={(event) => {
              setPagination({
                ...pagination,
                rowsPerPage: parseInt(event.target.value),
              });
            }}
          />
          {isModalOpen && isModalOpen.modalType === ModalType.Company && (
            <Company
              company={getCompany(isModalOpen.companyId)}
              onSubmit={() => setRefresh(new Date())}
              onClose={() => {
                setIsModalOpen(undefined);
                setRefresh(new Date());
              }}
            />
          )}
          {isModalOpen &&
            isModalOpen.modalType === ModalType.Users &&
            isModalOpen.partnerId && (
              <Users
                partnerId={isModalOpen.partnerId}
                onClose={() => setIsModalOpen(undefined)}
              />
            )}
        </Container>
      </ThemeProvider>
    </>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
