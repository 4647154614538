import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {
  Box,
  Button,
  styled,
  Container,
  CssBaseline,
  FormControl,
  GlobalStyles,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableCell,
  tableCellClasses,
  TablePagination,
  ThemeProvider,
} from "@mui/material";
import { PartnerDTO } from "../../../domain/Partner/PartnerDTO";
import { HttpService } from "../../../services/HttpService";
import { DocumentDTO } from "../../../domain/Document/DocumentDTO";
import "../Reporting/index.css";
import myTheme from "../../../helpers/themeHelper";

export const Reporting = () => {
  const [partners, setPartners] = useState<PartnerDTO[]>([]);
  const [filters, setFilters] = useState<{
    partnerId?: string;
    startDate: Date;
    endDate: Date;
  }>({ startDate: new Date(), endDate: new Date(), partnerId: undefined });
  const [documents, setDocuments] = React.useState<DocumentDTO[]>();

  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>({ page: 0, rowsPerPage: 10 });
  const navigate = useNavigate();

  useEffect(() => {
    HttpService.getInstance(navigate)
      .client.get<PartnerDTO[]>("/Partners")
      .then((response) => {
        setPartners(response.data);
      });
  }, [navigate]);

  const handleGenerate = () => {
    if (!filters?.partnerId) {
      return;
    }

    HttpService.getInstance(navigate)
      .client.get<DocumentDTO[]>(
        `/partners/${filters?.partnerId
        }/documents?start=${filters?.startDate.toUTCString()}&end=${filters?.endDate.toUTCString()}`
      )
      .then((response) => {
        setDocuments(response.data);
      });
  };

  const getDocuments = () => {
    if (!documents) return null;
    return _.orderBy(documents, (document) => document.fileName, "asc")
      .slice(
        pagination.page * pagination.rowsPerPage,
        (pagination.page + 1) * pagination.rowsPerPage
      )
      ?.map((document) => (
        <StyledTableRow key={document.id}>
          <StyledTableCell scope="row">
            {document.fileName}
          </StyledTableCell>
          <StyledTableCell align="right">
            {document.creationDate.toString()}
          </StyledTableCell>
          <StyledTableCell align="right">{document.companyId}</StyledTableCell>
          <StyledTableCell align="right">{document.credits}</StyledTableCell>
        </StyledTableRow>
      ));
  };

  return (
    <ThemeProvider theme={myTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Generate Report
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            mt: 4,
          }}
        >
          <FormControl sx={{ width: 200, mr: 50 }}>
            <InputLabel id="partnerSelectLabel">Partner</InputLabel>
            <Select
              placeholder="Select Partner"
              variant="outlined"
              labelId="partnerSelectLabel"
              id="partnerSelect"
              label="Partner"
              defaultValue="SelectPartner"
              onChange={(e) =>
                setFilters({ ...filters, partnerId: e.target.value })
              }
            >
              {partners?.map((partner: PartnerDTO) => (
                <MenuItem key={partner.id} value={partner?.id}>
                  {partner?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Box sx={{ width: '100px', alignItems: 'center', alignContent: 'center', mr: 1 }}>
              <Typography fontSize={'12px'} color={myTheme.palette.primary.main} sx={{ position: 'absolute', ml: '5px', mt: '-15px' }}>Start date</Typography>
              <DatePicker
                selected={filters.startDate}
                onChange={(date: Date) => {
                  date = new Date(date.setUTCHours(0, 0, 0, 0));
                  setFilters({ ...filters, startDate: date })
                }}
              />
            </Box>
            <Box sx={{ width: '100px', alignItems: 'center', alignContent: 'center' }}>
              <Typography fontSize={'12px'} color={myTheme.palette.primary.main} sx={{ position: 'absolute', ml: '5px', mt: '-15px' }}>End date</Typography>
              <DatePicker
                selected={filters.endDate}
                onChange={(date: Date) => {
                  date = new Date(date.setUTCHours(23, 59, 59, 999));
                  setFilters({ ...filters, endDate: date })
                }}
              />
            </Box>

            <Button
              sx={{ width: 100, ml: 2 }}
              variant={'outlined'}
              onClick={handleGenerate}
            >
              Generate
            </Button>
          </Box>
        </Box>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Document Name</StyledTableCell>
                <StyledTableCell align="right">Date</StyledTableCell>
                <StyledTableCell align="right">Company Name</StyledTableCell>
                <StyledTableCell align="right">Credits</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{getDocuments()}</TableBody>
          </Table>
        </TableContainer>
        <Typography align="right" sx={{ mt: 3, fontWeight: 'bold', fontSize: '20px' }}>
          {`TOTAL: ${_.sum(_.map(documents, "credits"))}`}
        </Typography>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={documents ? documents.length : 0}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={(event, index) => {
            setPagination({ ...pagination, page: index });
          }}
          onRowsPerPageChange={(event) => {
            setPagination({
              ...pagination,
              rowsPerPage: parseInt(event.target.value),
            });
          }}
        />
      </Container>
    </ThemeProvider>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    wordBreak: 'break-all'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
