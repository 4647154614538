import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { HttpService } from "../../services/HttpService";
import { useNavigate } from "react-router-dom";
import { Alert, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import myTheme from "../../helpers/themeHelper";
import { identity } from "lodash";
import { useUserIdentity } from "../../router/PrivateRoute";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserSettingsDTO } from "../../domain/Identity/UserSettingsDTO";
import { date } from "yup";
import KeyIcon from '@mui/icons-material/Key';
import DeleteIcon from '@mui/icons-material/Delete';
import ChangePasswordPopup from "./ChangePassword";
import { ChangePasswordDTO } from "./ChangePassword/ChangePasswordDTO";



export default function Settings() {
    const [userInfo, setUserInfo] = useState<UserSettingsDTO | undefined>(undefined);
    const navigate = useNavigate();
    const { userIdentity } = useUserIdentity();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (userInfo != undefined) {
            return;
        }
        var userID = userIdentity!.id;

        HttpService.getInstance(navigate)
            .client.post<UserSettingsDTO>(`/settings/${userID}`)
            .then((response) => {
                setUserInfo(response.data);
            })
            .catch((error) => {
                toast.error("User information is not available at the moment.", {
                    position: "top-center",
                    theme: "colored",
                });
            });
    }, [userInfo])


    const onIsModalOpenChanged = (newPassword: ChangePasswordDTO | undefined, requestedPassword: boolean) => {
        setIsModalOpen(false);
        if (requestedPassword == true) {
            requestNewPassword();
        }
        else if (newPassword != undefined) {
            changePassword(newPassword);
        }
    }
    const changePassword = (data:ChangePasswordDTO ) => {
        var userID = userIdentity!.id;
        const passwordDetails = {
            oldPassword: data.password,
            newPassword: data.newPassword,
          };
          HttpService.getInstance(navigate)
          .client.post(`/settings/updatePassword/${userID}`, passwordDetails)
          .then((response) => {
            toast.success(response.data, {
                position: "top-center",
                theme: "colored",
            });
          })
          .catch((error) => {
            toast.error("Password can not be changed at the moment.", {
                position: "top-center",
                theme: "colored",
            });
          });
    }
    const requestNewPassword = () => {
        var userID = userIdentity!.id;
        HttpService.getInstance(navigate)
            .client.post("/settings/changePassword", userID)
            .then((response) => {
                toast.success(response.data, {
                    position: "top-center",
                    theme: "colored",
                });
            })
            .catch((error) => {
                toast.error("Password can not be changed at the moment.", {
                    position: "top-center",
                    theme: "colored",
                });
            });
    }

    return (
        <ThemeProvider theme={myTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                        <AccountCircleIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        User Settings
                    </Typography>
                    <TableContainer component={Paper} style={{ margin: '50px' }}>
                        <Table aria-label="simple table" >
                            <TableBody>
                                <TableRow key={0}>
                                    <TableCell component="th" scope="row" style={{ color: myTheme.palette.primary.main, fontWeight: 'bold', }}>
                                        username :
                                    </TableCell>
                                    <TableCell align="center">{userInfo?.username}</TableCell>
                                </TableRow>
                                <TableRow key={1}>
                                    <TableCell component="th" scope="row" style={{ color: myTheme.palette.primary.main, fontWeight: 'bold', }}>
                                        email :
                                    </TableCell>
                                    <TableCell align="center">{userInfo?.emailAddress}</TableCell>
                                </TableRow>
                                <TableRow key={2}>
                                    <TableCell component="th" scope="row" style={{ color: myTheme.palette.primary.main, fontWeight: 'bold', }}>
                                        first name :
                                    </TableCell>
                                    <TableCell align="center">{userInfo?.firstName}</TableCell>
                                </TableRow>
                                <TableRow key={3}>
                                    <TableCell component="th" scope="row" style={{ color: myTheme.palette.primary.main, fontWeight: 'bold', }}>
                                        last name :
                                    </TableCell>
                                    <TableCell align="center">{userInfo?.lastName}</TableCell>
                                </TableRow>
                                <TableRow key={4}>
                                    <TableCell component="th" scope="row" style={{ color: myTheme.palette.primary.main, fontWeight: 'bold', }}>
                                        phone :
                                    </TableCell>
                                    <TableCell align="center">{userInfo?.phone}</TableCell>
                                </TableRow>
                                <TableRow key={5}>
                                    <TableCell component="th" scope="row" style={{ color: myTheme.palette.primary.main, fontWeight: 'bold', }}>
                                        role :
                                    </TableCell>
                                    <TableCell align="center">{userInfo && userInfo.roles && userInfo.roles[0]?.name}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
                        <Button
                            startIcon={<KeyIcon />}
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                            sx={{ mt: 3, mb: 1, mr: 1 }}
                        >
                            Change Password
                        </Button>
                        <Button
                            startIcon={<DeleteIcon />}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 1, ml: 1 }}
                        >
                            Delete Account
                        </Button>
                    </Box>

                </Box>
                {isModalOpen && (<ChangePasswordPopup onIsModalOpenChanged={onIsModalOpenChanged} />)}

            </Container>
        </ThemeProvider>
    );
}

export { Settings };
