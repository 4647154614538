import { TableSortLabel, Box, TableRow, TableHead, Checkbox } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useState } from "react";
import { DocumentDTO } from "../../../domain/Document/DocumentDTO";
import DocumentWorkflowStateDTO from "../../../domain/Document/DocumentWorkflowStateDTO";
import { StyledTableCell } from "./index.styled";
import { SortDirection } from "../index.types";

const DocumentCheckboxHeaderCellElement: React.FC<{ onCheck: (checked: boolean) => void }> = (props) => {
  const [checked, setChecked] = useState<boolean>(false);
  
  return (
    <StyledTableCell>
      <Checkbox
        color="primary"
        checked={checked}
        onChange={(event, checked) => {
          props.onCheck(checked);
          setChecked(checked);
        }}
      />
    </StyledTableCell>
  );
};

const HeaderCellElement: React.FC<{
  name: string,
  sortBy: keyof DocumentDTO,
  activeSorting: keyof DocumentDTO, 
  onSort: (sortBy: keyof DocumentDTO, sortDirection: SortDirection) => void
}> = (props) => {
  const { name, activeSorting, sortBy, onSort } = props;
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Ascending);

  const onSortLabelClick = () => {
    onSort(sortBy, sortDirection);
    setSortDirection(() => {
      return sortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
     });
  };

  return (
    <StyledTableCell
      align="left"
      key="fileName"
      sortDirection={sortDirection}
    >
      <TableSortLabel
        active={sortBy === activeSorting}
        direction={sortDirection}
        onClick={onSortLabelClick}
      >
        {name}
        {sortBy === activeSorting &&
          <Box component="span" sx={visuallyHidden}>
            {sortDirection === SortDirection.Descending
              ? "sorted descending"
              : "sorted ascending"}
          </Box>
        }
      </TableSortLabel>
    </StyledTableCell>
  );
};

const DocumentTableHeader: React.FC<{ 
  activeSorting: keyof DocumentDTO,
  documentStates: DocumentWorkflowStateDTO[],
  onCheck: (checked: boolean) => void,
  onSort: (sortBy: keyof DocumentDTO, sortDirection: SortDirection) => void
}> = (props) => (
  <TableHead>
    <TableRow>
      <DocumentCheckboxHeaderCellElement onCheck={props.onCheck} />
      <HeaderCellElement
        name="File name"
        sortBy="fileName"
        activeSorting={props.activeSorting}
        onSort={props.onSort} 
      />
      <HeaderCellElement
        name="Creation Date"
        sortBy="creationDate"
        activeSorting={props.activeSorting}
        onSort={props.onSort} 
      />
      <StyledTableCell />
    </TableRow>
  </TableHead>
);

export { DocumentTableHeader };