import { useMemo, useState } from "react";
import { Button, Popover, Box } from "@mui/material";
import { FileDownload, ExpandMore, Transform } from "@mui/icons-material";
import DocumentWorkflowStateDTO from "../../../domain/Document/DocumentWorkflowStateDTO";
import { DocumentActions, DocumentActionsEnum } from "./index.types";
import DocumentWorkflowStateTransitionsDTO from "../../../domain/Document/DocumentWorkflowStateTransitionDTO";
import PopupState, { bindTrigger, bindPopover, bindHover } from 'material-ui-popup-state';
import HoverPopover from "material-ui-popup-state/HoverPopover";

const ChangeStateActionElement: React.FC<{
  documentStates: DocumentWorkflowStateDTO[],
  onClose: () => void,
  changeState: (state: DocumentWorkflowStateDTO) => void
}> = (props) => {

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <>
          <Button
            sx={{ width: '100%' }}
            {...bindHover(popupState)}
          >
            <Transform /> Change state
          </Button>
          <HoverPopover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            disableRestoreFocus
          >
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "baseline" }}>
              {props.documentStates.map((state, index) => (
                <Button
                  key={index}
                  onClick={() => {
                    props.changeState(state);
                  }}
                >
                  {state.name}
                </Button>
              ))}
            </Box>
          </HoverPopover>
        </>
      )}
    </PopupState>
  );
};

const ExportActionElement: React.FC<{ onClose: () => void, export: (exportType: string) => void }> = (props) => {
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <>
          <Button
            {...bindHover(popupState)}
            sx={{ width: '100%', justifyContent: 'start' }}
          >
            <FileDownload /> Export
          </Button>
          <HoverPopover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            disableRestoreFocus
          >
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
              <Button
                onClick={() => {
                  props.export("excel");
                }}
              >
                Excel
              </Button>
              <Button
                onClick={() => {
                  props.export("excel NextUP");
                }}
              >
                Excel NextUP
              </Button>
              <Button
                onClick={() => {
                  props.export("xml");
                }}
              >
                XML
              </Button>
            </Box>
          </HoverPopover>
        </>
      )}
    </PopupState>
  );
};

const DocumentsActions: React.FC<ExportButtonProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const allowedDocumentStates = useMemo(() => {
    const documentStateTransitions = props.documentStatesTransitions.filter(transition => transition.currentWorkflowStateId === props.currentState?.id)
    const allowedStates = props.documentStates.filter(state =>
      documentStateTransitions.find(transition => transition.nextWorkflowStateId === state.id)
    );

    return allowedStates;
  }, [props.documentStates, props.documentStatesTransitions]);

  const showPopoverClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isPopoverOpen = !!anchorEl;

  return (
    <>
      <Button
        variant="contained"
        onClick={showPopoverClickHandler}
        sx={{ maxHeight: '55px', minWidth: '100px' }}
      >
        Actions <ExpandMore />
      </Button>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "baseline" }}>
          {props.documentActions.changeStateAction &&
            <ChangeStateActionElement
              documentStates={allowedDocumentStates}
              changeState={props.changeState}
              onClose={handleClose}
            />}
          {props.documentActions.exportAction &&
            <ExportActionElement
              onClose={handleClose}
              export={props.export}
            />}
        </Box>
      </Popover>
    </>
  );
};

export { DocumentsActions };

interface ExportButtonProps {
  documentActions: DocumentActions,
  currentState: DocumentWorkflowStateDTO | null,
  documentStates: DocumentWorkflowStateDTO[],
  documentStatesTransitions: DocumentWorkflowStateTransitionsDTO[],
  changeState: (state: DocumentWorkflowStateDTO) => void,
  export: (exportType: string) => void;
}
