import { useReducer } from "react";
import { DocumentsListState, SelectableDocument } from "../index.types";


  
  const initialState: DocumentsListState = {
    documents: [],
    openedDocument: undefined,
    shouldRefreshDocuments: false,
  };
  
  enum ACTION_TYPES {
    INIT,
    SET_DOCUMENTS,
    OPEN_DOCUMENT,
    SELECT_ALL,
    SELECT_DOCUMENT,
    REFRESH_DOCUMENTS
  }
  
  type Action = { type: ACTION_TYPES, payload?: { document?: SelectableDocument, documents?: SelectableDocument[], allSelected?: boolean } }
  
  const reducer = (state: DocumentsListState, action: Action): DocumentsListState => {
    switch(action.type) {
      case ACTION_TYPES.INIT: 
        return initialState;
      case ACTION_TYPES.SET_DOCUMENTS:
        return { ...state, documents: action.payload?.documents || state.documents };
      case ACTION_TYPES.OPEN_DOCUMENT: 
        return {...state, openedDocument: action.payload?.document };
      case ACTION_TYPES.SELECT_ALL: 
        const selectedDocuments = state.documents.map( ({ document }) => ({ document, isSelected: action.payload?.allSelected || false }));
        
        return { ...state, documents: selectedDocuments };
      case ACTION_TYPES.SELECT_DOCUMENT:
        const documents = [...state.documents];
        const index = state.documents.findIndex(({ document }) => document.id === action.payload?.document?.document.id);
        documents[index].isSelected = action.payload?.document?.isSelected || false;
  
        return { ...state, documents };
      case ACTION_TYPES.REFRESH_DOCUMENTS:
        return { ...state, shouldRefreshDocuments: !state.shouldRefreshDocuments };
      default: 
        return state;
    }
  };

  const useDocuments = () => useReducer(reducer, initialState);
  export { useDocuments, ACTION_TYPES };
  export type { Action, DocumentsListState, SelectableDocument };