import { Box, Button, Container, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField, ThemeProvider, Typography, colors } from '@mui/material'
import React from 'react'

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import myTheme from '../../helpers/themeHelper';
import myLogo from '../../assets/images/Logo1.png'
import SendIcon from '@mui/icons-material/Send';
import { FeedbackDTO } from './FeedbackDTO';
import ValidationSchema from "./index.validationSchema"
import { HttpService } from '../../services/HttpService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const FeedbackForm: React.FC<{}> = (props) => {

    const navigate = useNavigate();
    const [technicalIssues, setTechnicalIssues] = React.useState<boolean>();

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<FeedbackDTO>({
        resolver: yupResolver(ValidationSchema),
    });

    const handleOnSubmit = (data: FeedbackDTO) => {
        const jsonString = sessionStorage.getItem('userIdentity');

        if (jsonString) {

            const jsonObject = JSON.parse(jsonString);

            if (jsonObject && jsonObject.id) {
                data.name = jsonObject.name;
                data.phone = jsonObject.phone;
                data.username = jsonObject.username;
                data.email = jsonObject.email;
            }
        }
        HttpService.getInstance(navigate)
            .client.post("/Feedback", data)
            .then((response) => {
                if (response.status == 400) {
                    toast.error(response.data, {
                        position: "top-center",
                        theme: "colored",
                    });
                }
                else {
                    toast.success("Vă mulțumim pentru feedback-ul dvs.!", {
                        position: "top-center",
                        theme: "colored",
                    });
                }

            })
            .catch((error) => {
                toast.error("There was an error and the feedback was not submitted.", {
                    position: "top-center",
                    theme: "colored",
                });
            });
    }


    return (
        <ThemeProvider theme={myTheme}>
            <Container component="main" maxWidth="md">
                <Box component="form" justifyContent="center" onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container spacing={5} justifyContent="center" alignItems="center" direction="column">
                        <Grid item xs={12}>
                            <img src="https://salisterraweb.blob.core.windows.net/assets/Logo_transparent.png" alt="Documatik Logo" />
                        </Grid>
                        <Grid item xs={12}>
                            <h1>
                            Formular de Feedback pentru Utilizatorii Documatik
                            </h1>
                        </Grid>
                        <Grid item xs={12}>
                            <h3>
                            Vă mulțumim că ați ales să testați platforma Documatik pentru automatizarea introducerii datelor din facturi și bonuri fiscale. Apreciem feedback-ul dumneavoastra, deoarece ne ajută să ne îmbunătățim serviciile. Vă rugăm să completați formularul de mai jos.
                            </h3>
                        </Grid>


                    </Grid>
                    <Grid container spacing={5} justifyContent="center" alignItems="left" direction="column">
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question1)}>
                                <FormLabel id="question1label">
                                Cât de ușor a fost să schimbati parola și să începeți utilizarea Documatik?
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="question1label"
                                >
                                    <FormControlLabel {...register("question1")} value="verydifficult" control={<Radio />} label="Foarte dificil" />
                                    <FormControlLabel {...register("question1")} value="difficult" control={<Radio />} label="Dificil" />
                                    <FormControlLabel {...register("question1")} value="easy" control={<Radio />} label="Ușor" />
                                    <FormControlLabel {...register("question1")} value="veryeasy" control={<Radio />} label="Foarte ușor" />
                                </RadioGroup>
                                {errors.question1 && <FormHelperText>{errors.question1.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question2)}>
                                <FormLabel id="question2label">
                                Cât de intuitivă ați găsit interfața utilizatorului?
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="question2label"


                                >
                                    <FormControlLabel {...register("question2")} value="veryunintuitive" control={<Radio />} label="Foarte neintuitivă" />
                                    <FormControlLabel {...register("question2")} value="unintuitive" control={<Radio />} label="Neintuitivă" />
                                    <FormControlLabel {...register("question2")} value="intuitive" control={<Radio />} label="Intuitivă" />
                                    <FormControlLabel {...register("question2")} value="veryintuitive" control={<Radio />} label="Foarte intuitivă" />
                                </RadioGroup>
                                {errors.question2 && <FormHelperText>{errors.question2.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question3)}>
                                <FormLabel id="question3label">
                                Cât de mulțumit sunteți de acuratețea extragerii datelor din facturi și bonuri fiscale?
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="question3label"
                                >
                                    <FormControlLabel {...register("question3")} value="verydissatisfied" control={<Radio />} label="Foarte nemulțumit" />
                                    <FormControlLabel {...register("question3")} value="dissatisfied" control={<Radio />} label="Nemulțumit" />
                                    <FormControlLabel {...register("question3")} value="satisfied" control={<Radio />} label="Mulțumit" />
                                    <FormControlLabel {...register("question3")} value="verysatisfied" control={<Radio />} label="Foarte mulțumit" />
                                </RadioGroup>
                                {errors.question3 && <FormHelperText>{errors.question3.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question4)}>
                                <FormLabel id="question4label">
                                Ați întâmpinat probleme tehnice în timpul utilizării Documatik?
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="question4label"


                                    onChange={(e) => {
                                        setTechnicalIssues(e.target.value === 'true');
                                    }}
                                >
                                    <FormControlLabel {...register("question4")} value="true" control={<Radio />} label="Da" />
                                    <FormControlLabel {...register("question4")} value="false" control={<Radio />} label="Nu" />
                                </RadioGroup>
                                {errors.question4 && <FormHelperText>{errors.question4.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        {technicalIssues && <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question5)}>
                                <FormLabel id="question5label">
                                Dacă ați răspuns "Da", vă rugăm să detaliați problemele întâmpinate:
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="Probleme tehnice"
                                    multiline
                                    maxRows={6}
                                    {...register("question5")}
                                />
                                {errors.question5 && <FormHelperText>{errors.question5.message}</FormHelperText>}
                            </FormControl>
                        </Grid>}
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question6)}>
                                <FormLabel id="question6label">
                                Cât de mulțumit sunteți de viteza de procesare a documentelor?

                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="question6label"

                                    name="question6-radio-buttons-group"
                                >
                                    <FormControlLabel {...register("question6")} value="verydissatisfied" control={<Radio />} label="Foarte nemulțumit" />
                                    <FormControlLabel {...register("question6")} value="dissatisfied" control={<Radio />} label="Nemulțumit" />
                                    <FormControlLabel {...register("question6")} value="satisfied" control={<Radio />} label="Mulțumit" />
                                    <FormControlLabel {...register("question6")} value="verysatisfied" control={<Radio />} label="Foarte mulțumit" />
                                </RadioGroup>
                                {errors.question6 && <FormHelperText>{errors.question6.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question7)}>
                                <FormLabel id="question7label">
                                Cât de utile au fost informațiile și ghidurile disponibile?
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="question7label"

                                    name="question7-radio-buttons-group"
                                >
                                    <FormControlLabel {...register("question7")} value="veryuseless" control={<Radio />} label="Foarte inutile" />
                                    <FormControlLabel {...register("question7")} value="useless" control={<Radio />} label="Inutile" />
                                    <FormControlLabel {...register("question7")} value="useful" control={<Radio />} label="Utile" />
                                    <FormControlLabel {...register("question7")} value="veryuseful" control={<Radio />} label="Foarte utile" />
                                </RadioGroup>
                                {errors.question7 && <FormHelperText>{errors.question7.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question8)}>
                                <FormLabel id="question8label">
                                Ce funcționalități suplimentare ați dori să vedeți în viitor?
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="Funcționalități"
                                    multiline
                                    maxRows={6}
                                    {...register("question8")}
                                />
                                {errors.question8 && <FormHelperText>{errors.question8.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question9)}>
                                <FormLabel id="question9label">
                                Ați recomanda Documatik altor persoane?

                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="question9label"

                                    name="question9-radio-buttons-group"
                                    onChange={(e) => {
                                        setTechnicalIssues(e.target.value === 'yes');
                                    }}
                                >
                                    <FormControlLabel {...register("question9")} value="true" control={<Radio />} label="Da" />
                                    <FormControlLabel {...register("question9")} value="false" control={<Radio />} label="Nu" />
                                </RadioGroup>
                                {errors.question9 && <FormHelperText>{errors.question9.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question10)}>
                                <FormLabel id="question10label">
                                Aveți alte comentarii sau sugestii pentru îmbunătățirea Documatik?
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="Comentarii"
                                    multiline
                                    maxRows={6}
                                    {...register("question10")}
                                />
                                {errors.question10 && <FormHelperText>{errors.question10.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div style={{ height: "2vh" }}></div>
                    <div style={{ justifyContent: "center", alignItems: "left", width: '100%', display: 'flex' }}>
                        <Button
                            startIcon={<SendIcon />}
                            variant="contained"
                            type="submit"
                            component="button"
                        >
                            Trimite Feedback
                        </Button>
                    </div>

                    <div style={{ height: "10vh" }}></div>
                </Box>



            </Container>
        </ThemeProvider>
    )
}

export default FeedbackForm;