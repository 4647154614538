import { List, ListItem, ListItemText } from "@mui/material";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useUserIdentity } from "../../../router/PrivateRoute";

export const PartnerAdminMenu = () => {
  const location = useLocation();
  const path = location.pathname;
  const { userIdentity } = useUserIdentity();

  const partnerId = userIdentity?.partnerId;
  return (
    <List>
      <ListItem
        component={Link}
        to={`/${partnerId}/companies`}
        button
        key={"2"}
        selected={"/companies" === path}
      >
        <ListItemText primary={"Companies"} />
      </ListItem>
    </List>
  );
};
