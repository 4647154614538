import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  Typography,
  DialogContent,
  TextField,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { PartnerDTO } from "../../../domain/Partner/PartnerDTO";
import { HttpService } from "../../../services/HttpService";
import ValidationSchema from "../../Partners/Partner/index.validationSchema";

type Props = { partner: PartnerDTO; onClose: () => void };
const Partner: React.FC<Props> = (props) => {
  const { partner, onClose } = props;
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<PartnerDTO>({
    resolver: yupResolver(ValidationSchema),
    defaultValues: partner,
  });

  const handleOnSubmit = (data: PartnerDTO) => {
    const payload = {
      id: data.id,
      name: data.name,
      cui: data.cui,
      email: data.email,
      phone: data.phone,
    };
    if (partner.id) {
      HttpService.getInstance(navigate)
        .client.put("/Partners", payload)
        .then((response) => {
          toast.success("Partner edited successfully!", {
            position: "top-center",
            theme: "colored",
          });
          onClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      HttpService.getInstance(navigate)
        .client.post("/Partners", payload)
        .then((response) => {
          toast.success("Partner created successfully!", {
            position: "top-center",
            theme: "colored",
          });
          onClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <React.Fragment>
      <Dialog open={true} onClose={() => onClose()}>
        <DialogTitle>Complete Data</DialogTitle>
        <DialogContentText>
          <Typography sx={{ ml: 3 }}>
            Please provide the following information about the partner
          </Typography>
        </DialogContentText>
        <DialogContent dividers>
          <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
            <TextField
              margin="normal"
              fullWidth
              label="Name"
              autoFocus
              {...register("name")}
              error={!!errors.name}
              helperText={errors?.name?.message}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Cui"
              autoFocus
              {...register("cui")}
              error={!!errors.cui}
              helperText={errors?.cui?.message}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Email"
              autoFocus
              {...register("email")}
              error={!!errors.email}
              helperText={errors?.email?.message}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Phone"
              autoFocus
              {...register("phone")}
              error={!!errors.phone}
              helperText={errors?.phone?.message}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              component="button"
              sx={{ mt: 2 }}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export { Partner };
