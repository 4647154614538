import React, { useMemo, useState } from "react";
import { Container, Paper, Table, TableContainer, TablePagination } from "@mui/material";
import Document from '../Document/index';
import { DocumentDTO } from "../../../domain/Document/DocumentDTO";
import DocumentWorkflowStateDTO from "../../../domain/Document/DocumentWorkflowStateDTO";
import { ISorting, SelectableDocument, SortDirection } from "../index.types";
import { DocumentTableHeader } from "./DocumentTableHeader";
import { DocumentTableBodyElement } from "./DocumentTableBody";
import DocumentsActionsElement from "../DocumentsListActions";
import { IDocumentFilter } from "..";
import { exportTo } from '../../../helpers/DocumentHelpers';
import { CompanyDTO } from "../../../domain/Company/CompanyDTO";
import DocumentsUploader from "../DocumentsUploader";
import { DocumentTypeDTO } from "../../../domain/Document/DocumentType";
import { NavigateFunction, } from "react-router-dom";
import { DocumentWorkflowStateIds } from "../../../domain/Document/DocumentWorkflowStatesIds";
import DocumentWorkflowStateTransitionsDTO from "../../../domain/Document/DocumentWorkflowStateTransitionDTO";
import { DocumentActions } from "../DocumentsListActions/index.types";
import { toast } from "react-toastify";
import { HttpService } from "../../../services/HttpService";
import "./style.css"

const DocumentsList: React.FC<{
  company: CompanyDTO,
  documents: SelectableDocument[],
  documentStates: DocumentWorkflowStateDTO[],
  documentStatesTransitions: DocumentWorkflowStateTransitionsDTO[],
  filters: IDocumentFilter,
  openedDocument?: SelectableDocument,
  refreshState?: Date,
  navigate: NavigateFunction,
  onCloseDocument: () => void,
  onStartDateChange: (date: Date) => void,
  onEndDateChange: (date: Date) => void,
  onDocumentNameClick: (document: SelectableDocument) => void,
  onAllDocumentsCheckChanged: (allSelected: boolean) => void,
  onDocumentCheckChanged: (document: SelectableDocument) => void,
  onDocumentTypeChange: (documentType: DocumentTypeDTO) => void,
  onDocumentStateChange: (documentState: DocumentWorkflowStateDTO) => void,
  revalidateDocuments: () => void,
  onRefreshChanged: (refreshDate : Date) => void
}> = (props) => {
  const { documents, documentStates, documentStatesTransitions, filters, company,refreshState, navigate, revalidateDocuments,
    onEndDateChange, onStartDateChange, onDocumentTypeChange, onDocumentStateChange,onRefreshChanged  } = props;

  const selectedDocuments = useMemo(() =>
    documents.reduce((documents: DocumentDTO[], document) => document.isSelected ? [...documents, document.document] : documents, [])
    , [documents]);

  const documentActions = useMemo(() => {
    if (selectedDocuments.length === 0 || !filters.documentState) {
      return { changeStateAction: false, exportAction: false } as DocumentActions;
    }

    let res: DocumentActions;
    switch (filters.documentState.id) {
      case DocumentWorkflowStateIds.Approved:
        res = { changeStateAction: true, exportAction: true };
        break;
      case DocumentWorkflowStateIds.HighConfidence:
      case DocumentWorkflowStateIds.Modified:
      case DocumentWorkflowStateIds.Archived:
      case DocumentWorkflowStateIds.LowConfidence:
        res = { changeStateAction: true, exportAction: false };
        break;
      default:
        res = { changeStateAction: false, exportAction: false };
        break;
    }

    return res;
  }, [selectedDocuments, filters.documentState]);

  const exportDocument = (exportType: string) => {
    const callBack = () => revalidateDocuments();
    exportTo(selectedDocuments, exportType, navigate, callBack);
  };

  const updateDocumentsState = (id: string) => {
    //console.log(`Document $ state changed to : ${id}\n`);
    const toastId = toast.loading("Operation is pending.", {
      position: "top-center",
      theme: "colored",
    });

    const ids = selectedDocuments.map(document => document.id);

    var i = 0;
    for(i = 0;i<selectedDocuments.length;i++)
      {
        //console.log(`${selectedDocuments[i].fileName}\n`);
      }
    
    HttpService.getInstance(navigate)
      .client.put<DocumentDTO[]>(`/documents/states?newStateId=${id}`, ids)
      .then((res) => {
        revalidateDocuments();
        if (res.status !== 200) {
          return;
        }

        if (res.data.length > 0) {
          const listOfDocumentsNotUpdated = res.data.map((document, i) => <div key={i}><br />-{document.fileName}</div>);

          toast.update(toastId, {
            render: <div>The following documents have fields that need to be filled or the VAT number does not match with your company: {listOfDocumentsNotUpdated}</div>,
            type: "info",
            isLoading: false,
            autoClose: null,
          });

          return;
        }
        
        toast.update(toastId, {
          render: "The document states were successfully updated.",
          type: "success",
          isLoading: false,
          autoClose: null,
          
        });
      })
      .catch(() => toast.dismiss(toastId));
  };

  return (
    <Container maxWidth="md" component="main">
      <DocumentsUploader company={company} documentType={filters.documentType} navigate={navigate} />
      <DocumentsActionsElement
        filters={filters}
        documentStates={documentStates}
        documentStatesTransitions={documentStatesTransitions}
        documentActions={documentActions}
        export={exportDocument}
        onStartDateFilterChange={onStartDateChange}
        onEndDateFilterChange={onEndDateChange}
        onStateFilterChange={onDocumentStateChange}
        onDocumentStateUpdate={(documentState) => updateDocumentsState(documentState.id)}
        onTypeFilterChange={onDocumentTypeChange}
        onRefreshChanged={onRefreshChanged}
      />
      <DocumentTableElement
        documents={documents}
        documentStates={documentStates}
        onDocumentNameClick={props.onDocumentNameClick}
        onAllDocumentsCheckChanged={props.onAllDocumentsCheckChanged}
        onDocumentCheckChanged={props.onDocumentCheckChanged}
        navigate={props.navigate}
      />
      {props.openedDocument &&
        <Document
          document={props.openedDocument.document}
          documentStates={documentStates}
          navigate={navigate}
          onClose={props.onCloseDocument}
          revalidateDocument={revalidateDocuments}
        />}
    </Container>
  );
};

const DocumentTableElement: React.FC<{
  documents: SelectableDocument[],
  documentStates: DocumentWorkflowStateDTO[],
  onDocumentNameClick: (document: SelectableDocument) => void,
  onDocumentCheckChanged: (document: SelectableDocument) => void,
  onAllDocumentsCheckChanged: (checked: boolean) => void,
  navigate: NavigateFunction
}> = (props) => {
  const [sorting, setSorting] = useState<ISorting<DocumentDTO>>(
    { sortBy: "creationDate", sortDirection: SortDirection.Ascending }
  );
  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>({ page: 0, rowsPerPage: 10 });

  const sortedDocumentsRows = useMemo(() => sortSelectableDocuments(props.documents, sorting.sortBy, sorting.sortDirection)
    .slice(pagination.page * pagination.rowsPerPage, (pagination.page + 1) * pagination.rowsPerPage)
    , [props.documents, sorting, pagination]);

  const onSort = (sortBy: keyof DocumentDTO, sortDirection: SortDirection) => {
    setSorting({ sortBy, sortDirection });
  };

  return (
    <TableContainer component={Paper} sx={{ padding: 0 }}>
      <Table sx={{ padding: 0 }} aria-label="customized table">
        <DocumentTableHeader
          activeSorting={sorting.sortBy}
          documentStates={props.documentStates}
          onCheck={props.onAllDocumentsCheckChanged}
          onSort={onSort}
        />
        <DocumentTableBodyElement
          documents={sortedDocumentsRows}
          documentStates={props.documentStates}
          onDocumentCheckChanged={props.onDocumentCheckChanged}
          onDocumentNameClick={props.onDocumentNameClick}
          navigate={props.navigate}
        />
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={props.documents ? props.documents.length : 0}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        onPageChange={(event, index) => {
          setPagination({ ...pagination, page: index });
        }}
        onRowsPerPageChange={(event) => {
          setPagination({
            ...pagination,
            rowsPerPage: parseInt(event.target.value),
          });
        }}
      />
    </TableContainer>
  );
};

const sortSelectableDocuments = (selectableDocuments: SelectableDocument[], sortBy: keyof DocumentDTO, sortDirection: SortDirection): SelectableDocument[] =>
  selectableDocuments.sort((a, b) => {
    switch (typeof a.document[sortBy]) {
      case "number": {
        return sortDirection == SortDirection.Ascending
          ? (a.document[sortBy] as number) - (b.document[sortBy] as number)
          : (b.document[sortBy] as number) - (a.document[sortBy] as number);
      }

      case "string": {
        return sortDirection == SortDirection.Ascending
          ? a.document[sortBy].toLocaleString().localeCompare(b.document[sortBy].toLocaleString())
          : b.document[sortBy].toLocaleString().localeCompare(a.document[sortBy].toLocaleString());
      }

      case "object": {
        if (a.document[sortBy] instanceof Date) {
          return sortDirection == SortDirection.Ascending
            ? (a.document[sortBy] as Date) < (b.document[sortBy] as Date) ? -1 : 1
            : (b.document[sortBy] as Date) < (a.document[sortBy] as Date) ? -1 : 1;
        }
        return 0;
      }
    }
  });

export { DocumentsList };
