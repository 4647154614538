import * as yup from "yup";

const requiredValidationMessage = "This field is required.";
const requiresNumberMessage = "This field requires a number.";
const requiresPositiveNumberMessage = "This field requires a positive number.";
const requiresDate = "This field requires a date formatted as 'dd.MM.yyyy'";
const totalVatErrorMessage = "Total VAT must be equal to Subtotal + Vat";
const nonNegativeNumberMessage = "This field cannot be a negative number.";

const validationSchema = yup.object().shape({
  vendorName: yup.string().trim().required(requiredValidationMessage).typeError(requiredValidationMessage),
  vendorTaxId: yup.string().trim().required(requiredValidationMessage).typeError(requiredValidationMessage),
  subTotal: yup.number().required(requiresNumberMessage).typeError(requiresNumberMessage),
  totalVat: yup.number().required(requiresNumberMessage).typeError(requiresNumberMessage),
  invoiceTotal: yup.number()
    .required(requiresNumberMessage)
    .typeError(requiresNumberMessage)
    .test('total-vat-equals-sum', totalVatErrorMessage, function (value) {
      const { subTotal, totalVat } = this.parent;
      if (value != undefined) {
        var difference = Math.abs(totalVat + subTotal - value);
        if(difference < 0.1)
          {
            return true;
          }
      }

      return false;
    }),
  invoiceId: yup.string().trim().required(requiredValidationMessage).typeError(requiredValidationMessage),
  invoiceDate: yup
    .string()
    .trim()
    .test("is-date", requiresDate, x => {
      if (!x) return false;

      const split = x.split(".");

      if (split.length !== 3) return false;

      return (!Number.isNaN(Date.parse(`${split[2]}-${split[1]}-${split[0]}`)));
    })
    .typeError(requiresDate),
  dueDate: yup
    .string()
    .trim()
    .nullable(true)
    .test("is-nullable-date", requiresDate, x => {
      if (!x) return true;

      const split = x.split(".");

      if (split.length !== 3) return false;

      return (!Number.isNaN(Date.parse(`${split[2]}-${split[1]}-${split[0]}`)));
    })
    .typeError(requiresDate),
  currencyCode: yup.string().trim().required(requiredValidationMessage).typeError(requiredValidationMessage),
  accountingNoteEntries: yup.array(
    yup.object({
      creditAccount: yup
        .string()
        .trim()
        .test("is-account", requiresNumberMessage, x => {
          if (!x) return false;
          if (x === "N/A") return true;
          if (!Number.isNaN(Number.parseFloat(x))) return true;
          return false;
        })
        .typeError(requiresNumberMessage),
      debitAccount: yup
        .string()
        .trim()
        .test("is-account", requiresNumberMessage, x => {
          if (!x) return false;
          if (x === "N/A") return true;
          if (!Number.isNaN(Number.parseFloat(x))) return true;
          return false;
        })
        .typeError(requiresNumberMessage),
      value: yup
        .number()
        .required(requiresNumberMessage)
        .typeError(requiresNumberMessage),
      quantity: yup
        .number()
        .required(requiresPositiveNumberMessage)
        .typeError(requiresPositiveNumberMessage),
      description: yup.string().trim().required(requiredValidationMessage).typeError(requiredValidationMessage)
    })
  ),
});

export default validationSchema;
