import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { UserDTO } from "../../domain/Identity/UserDTO";
import { HttpService } from "../../services/HttpService";
import ValidationSchema from "./index.validationschema";
import { toast } from "react-toastify";
import { AppRoles } from "../../domain/Identity/AppRoles";

export const Users: React.FC<{
  partnerId: string;
  onClose: () => void;
}> = (props) => {
  const { partnerId, onClose } = props;

  const navigate = useNavigate();
  const [users, setUsers] = useState<UserDTO[]>([]);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserDTO>({
    resolver: yupResolver(ValidationSchema),
  });

  const handleOnSubmit = async (data: UserDTO) => {
    const user = {
      userName: data.username,
      emailAddress: data.emailAddress,
      firstName: data.firstName,
      lastName: data.lastName,
      roleName: data.roleName,
      partnerId: partnerId,
      phone: data.phone,
    };

    HttpService.getInstance(navigate)
      .client.post("auth/register", user)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Request Failed");
        }
        toast.success("Email sent with user created!", {
          position: "top-center",
          theme: "colored",
        });
        onClose();
      })
  };
  useEffect(() => {
    HttpService.getInstance(navigate)
      .client.get<UserDTO[]>(`/Partners/${partnerId}/users`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigate, partnerId]);

  return (
    <React.Fragment>
      <Container>
        <Dialog fullWidth={true} maxWidth={"xl"} open={true}>
          <DialogTitle>Users management</DialogTitle>
          <DialogContent>
            <Box component="form">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  sx={{ borderRight: "1px solid lightgrey", pr: 2 }}
                >
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Username"
                    autoFocus
                    {...register("username")}
                    error={!!errors.username}
                    helperText={errors?.username?.message}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Email"
                    autoFocus
                    {...register("emailAddress")}
                    error={!!errors.emailAddress}
                    helperText={errors?.emailAddress?.message}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="roleSelectLabel">Role</InputLabel>

                    <Select
                      {...register("roleName")}
                      error={!!errors.roleName}
                      required
                      label="Role"
                      variant="outlined"
                    >
                      <MenuItem value={AppRoles.Accountant}>
                        Accountant
                      </MenuItem>
                      <MenuItem value={AppRoles.PartnerAdmin}>
                        Partner Admin
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    margin="normal"
                    fullWidth
                    label="First Name"
                    autoFocus
                    {...register("firstName")}
                    error={!!errors.firstName}
                    helperText={errors?.firstName?.message}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Last Name"
                    autoFocus
                    {...register("lastName")}
                    error={!!errors.lastName}
                    helperText={errors?.lastName?.message}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Phone Number"
                    autoFocus
                    {...register("phone")}
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TableContainer sx={{ mt: 2 }} component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>First Name</TableCell>
                          <TableCell align="right">Last Name</TableCell>
                          <TableCell align="right">Email</TableCell>
                          <TableCell align="right">Phone</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users.map((user) => (
                          <TableRow
                            key={user.username}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {user.firstName}
                            </TableCell>
                            <TableCell align="right">{user.lastName}</TableCell>
                            <TableCell align="right">
                              {user.emailAddress}
                            </TableCell>
                            <TableCell align="right">{user.phone}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Close</Button>
            <Button variant="outlined" onClick={handleSubmit(handleOnSubmit)}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </React.Fragment>
  );
};
