import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CssBaseline, GlobalStyles, Container } from '@mui/material';
import { registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// Import FilePond styles:
import "filepond/dist/filepond.min.css";
import "./index.css";

import { HttpService } from '../../services/HttpService';
import { CompanyDTO } from '../../domain/Company/CompanyDTO';
import { DocumentDTO } from '../../domain/Document/DocumentDTO';
import { DocumentTypeDTO } from '../../domain/Document/DocumentType';
import { DocumentsList } from './DocumentsList';
import DocumentWorkflowStateDTO from '../../domain/Document/DocumentWorkflowStateDTO';
import { ACTION_TYPES, SelectableDocument, useDocuments } from './DocumentsList/reducer';
import DocumentWorkflowStateTransitionsDTO from '../../domain/Document/DocumentWorkflowStateTransitionDTO';

// Register the plugin:
registerPlugin(FilePondPluginFileValidateType);

type IDocumentFilter = {
  startDate: Date,
  endDate: Date,
  documentType: DocumentTypeDTO,
  documentState: DocumentWorkflowStateDTO | null
};

const Documents: React.FC<{}> = () => {
  const [refresh,setRefresh] = useState<Date>();
  const [company, setCompany] = useState<CompanyDTO>();
  const [state, dispatch] = useDocuments();
  const [documentStates, setDocumentStates] = useState<DocumentWorkflowStateDTO[]>([]);
  const [documentStatesTransitions, setDocumentStatesTransitions] = useState<DocumentWorkflowStateTransitionsDTO[]>([]);
  const navigate = useNavigate();
  const { companyCui } = useParams();
  const [filters, setFilters] = useState<IDocumentFilter>({ 
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)), 
    endDate: new Date(), 
    documentState: null, 
    documentType: DocumentTypeDTO.Invoice
  });

  useEffect(() => {
    HttpService.getInstance(navigate).client
    .get<DocumentWorkflowStateDTO[]>(`/workflowStates/documents`)
    .then(({ data }) => {
      setDocumentStates(data.sort((d1, d2) => d1.orderCode - d2.orderCode));
      const documentState = data.length > 0 && data[0];
      if (documentState){
        setFilters({ ...filters, documentState });
      }
    });
    HttpService.getInstance(navigate).client
    .get<DocumentWorkflowStateTransitionsDTO[]>("/workflowStateTransitions")
    .then(({ data }) => {
      if (data)
        setDocumentStatesTransitions(data);
    }); 
  }, []);

  useEffect(() => {
    if (!companyCui) {
      return;
    }
    HttpService.getInstance(navigate).client
      .get<CompanyDTO>(`/Companies/${companyCui}`)
      .then(({ data }) => setCompany(data));
  }, [companyCui, navigate]);

  useEffect(() => {
    if (!company?.id) {
      return;
    }

    HttpService.getInstance(navigate).client
      .get<DocumentDTO[]>(
        `/documents/filtered/${company.id}?start=${filters.startDate.toUTCString()}&end=${filters.endDate.toUTCString()}&workflowStateId=${filters.documentState?.id}`
      )
      .then(({ data }) => {
        const documents = data.reduce((selectableDocuments: SelectableDocument[], document) => (
                            document.documentType === filters.documentType ? [...selectableDocuments, { document, isSelected: false }] : selectableDocuments
                          ), []);
        dispatch({ type: ACTION_TYPES.SET_DOCUMENTS, payload: { documents }});
      });
  }, [company, filters, navigate, state.shouldRefreshDocuments]);

  const onEndDateChange = (date: Date) => {
    const endDate = new Date(date.setUTCHours(0, 0, 0, 0));
    setFilters({ ...filters, endDate });
  };

  const onStartDateChange = (date: Date) => {
    const startDate = new Date(date.setUTCHours(0, 0, 0, 0));
    setFilters({ ...filters, startDate });
  };

  const onDocumentTypeChange = (documentType: DocumentTypeDTO) => {
    setFilters({ ...filters, documentType });
  };

  const onDocumentStateChange = (documentState: DocumentWorkflowStateDTO) => {
    setFilters({ ...filters, documentState });
  }

  const onRefreshChanged = (refreshDate: Date) => {
    setRefresh(refreshDate);
    revalidateDocuments();
  }

  const onDocumentNameClick = (document: SelectableDocument) => dispatch({ type: ACTION_TYPES.OPEN_DOCUMENT, payload: { document }});
  const onAllDocumentsCheckChanged = (allSelected: boolean) => dispatch({ type: ACTION_TYPES.SELECT_ALL, payload: { allSelected } });
  const onDocumentCheckChanged = (document: SelectableDocument) => dispatch({ type: ACTION_TYPES.SELECT_DOCUMENT, payload: { document} });
  const onCloseDocument = () => dispatch({ type: ACTION_TYPES.OPEN_DOCUMENT });
  const revalidateDocuments = () => dispatch({ type: ACTION_TYPES.REFRESH_DOCUMENTS });
  
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      { company?.id &&
        <DocumentsList
          company={company}
          filters={filters}
          documents={state.documents}
          documentStates={documentStates}
          documentStatesTransitions={documentStatesTransitions}
          onDocumentNameClick={onDocumentNameClick}
          onAllDocumentsCheckChanged={onAllDocumentsCheckChanged}
          onDocumentCheckChanged={onDocumentCheckChanged}
          navigate={navigate}
          onEndDateChange={onEndDateChange}
          onStartDateChange={onStartDateChange}
          onDocumentTypeChange={onDocumentTypeChange}
          onDocumentStateChange={onDocumentStateChange}
          openedDocument={state.openedDocument} 
          onCloseDocument={onCloseDocument}
          revalidateDocuments={revalidateDocuments}
          onRefreshChanged={onRefreshChanged}
        />}       
      <DocumentsFooterElement />
    </React.Fragment>
  );
};

const DocumentsFooterElement: React.FC<{}> = (props) => (
  <Container
    maxWidth="md"
    component="footer"
    sx={{
      borderTop: (theme) => `1px solid ${theme.palette.divider}`,
      mt: 6,
    }}
  />
);

export { Documents };
export type { IDocumentFilter };

