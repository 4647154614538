import _ from "lodash";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Layout } from "../components/Layout/Layout";
import { AppRoles } from "../domain/Identity/AppRoles";
import { UserIdentity } from "../domain/Identity/UserIdentity";

export const useUserIdentity = (): { userIdentity: UserIdentity | null } => {
  const _user = sessionStorage.getItem("userIdentity");

  if (!_user) {
    return { userIdentity: null };
  }

  const userIdentity: UserIdentity = JSON.parse(_user);
  return { userIdentity };
};

export const PrivateRoute = ({ roles }: { roles: Array<AppRoles> }) => {
  const { userIdentity } = useUserIdentity();
  const location = useLocation();

  if (!userIdentity) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const authorized = _.intersection(userIdentity.roles, roles).length !== 0;
  return authorized ? (
    <Layout />
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};
