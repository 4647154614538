enum DocumentWorkflowStateIds {
  Modified = 'ed8acd9b-59d8-45e6-b21f-4e1da6ef10c8',
  HighConfidence = '3391ec90-0caf-4798-bb34-50ec6b937824',
  Processing = 'df1cf044-963c-480b-8100-2e8779224f6c',
  Uploaded = '6f638aa0-46b6-44ab-aef3-53f4c051060d',
  Archived = '4122f0ac-3327-4980-af93-7b32b028ce78',
  Approved = 'b3ad9720-6c33-4e45-bbe7-9bcc58eec4c3',
  LowConfidence = '39fb247f-3493-477d-ad27-c688b5c5b70e',
  Scrapped = '8746d372-6e06-4316-8814-c72eee6fed1d',
  Failed = 'd4bb69b3-7f6e-4f5a-963c-e9b4a3d00d48',
}

export { DocumentWorkflowStateIds };
