import { styled, TableCell, tableCellClasses, TableRow } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.action.activatedOpacity,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      maxWidth: "355px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    cursor: "pointer",
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

export { StyledTableCell, StyledTableRow };